<template>
  <!-- 我的考试 -->
  <div class="quesTion">
    <kind-choose-view :show-kc="true" :type="7" :is-que="isQue" cookies-is-ok="isOk" :entrants-type="3"
      :test-paper-type="testPaperType" @isOk="isOk" />
    <div class="qustionList">
      <!-- 试卷包 -->
      <div v-if="list && list.length > 0 && search.examType == 1" class="quesTion-car">
        <div v-for="(item, index) in list" :key="index" class="item page">
          <img class="item-img examPage" src="@/assets/img/homeSeventh/examPage.png" />
          <div class="item-content">
            <div class="item-title twoEllipsis">{{ item.name }}</div>
            <div class="item-tips elp">
              共{{ item.paperNameList ? item.paperNameList.length : 0 }}套试卷
            </div>
            <div class="item-but">
              <div class="colleges-price">
                <span class="price-icon">￥</span>{{ item.packagePrice }}
              </div>
              <div class="colleges-learn" @click="goPage(item)">查看试卷</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 试卷  -->
      <div v-if="list && list.length > 0 && search.examType == 2" class="quesTion-car">
        <div v-for="(item, index) in list" :key="index" class="item">
          <img class="item-img" src="@/assets/img/homeSeventh/quesTionIcon.png" />
          <div class="item-content">
            <div class="item-title twoEllipsis">{{ item.name }}</div>
            <div class="item-but">
              <div class="colleges-price">
                <span class="price-icon">￥</span>{{ item.paperPrice }}
              </div>
              <div v-if="item.paperPrice > 0 && item.status == 2">
                <div v-if="!item.seeNum" class="colleges-learn" @click="goOrder(12, item)">
                  立即购买
                </div>
                <div v-else class="colleges-learn" @click="goLearn(item, false)">
                  免费试看
                </div>
              </div>
              <div v-else class="colleges-learn" @click="goLearn(item, true)">
                马上做题
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 暂无课程 -->
      <div v-if="!list || list.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
        :page-sizes="[9, 15, 21, 27]" :page-size="pageSize" :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";

import { getInfo, getToken } from "@/api/cookies";
import KindChooseView from "@/components/kindChooseViewsSeventh.vue";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import { AnswerClient } from "@/api/task/answerClient";
const answerClient = new AnswerClient();
export default {
  components: { KindChooseView },

  data() {
    return {
      list: [], // 查询列表
      getInfo: null,
      search: {
        examType: 1,
      },
      pageSize: 9,
      pageNum: 1,
      total: 0,

      /* 考点讲解显示 */
      ifPlace: false,
      isQue: false,
      thisList: null,
      testPaperType: null,
    };
  },
  async created() {
    this.testPaperType = this.$route.query.type;
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    /* 获取列表 */
    getList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "",
        background: "rgba(0, 0, 0, 0.1)",
      });
      if (this.search.type == 4002) {
        /* 考点练习 */
        answerClient
          .getPcExamPlaceList(
            this.pageSize,
            this.pageNum,
            this.search.kind ? this.search.kind : undefined,
            this.search.zyId ? this.search.zyId : undefined,
            this.search.kcId,
            undefined,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            this.list = res.rows;
            this.total = res.total;
            loading.close();
          });
      } else {
        if (this.search.examType == 1) {
          /* 试卷包 */
          answerClient
            .getNewTenantPackageListToPc(
              this.pageSize,
              this.pageNum,
              this.search.kind ? this.search.kind : undefined,
              this.search.stageName ? this.search.stageName : undefined,
              this.search.zyId ? this.search.zyId : undefined,
              this.search.type ? this.search.type : undefined,
              this.userInfo ? this.userInfo.id : undefined,
              this.search.kcId,
              this.search.name
            )
            .then((res) => {
              if (res.code) {
                this.list = [];
                this.total = 0;
              } else {
                this.list = res.rows;
                this.total = res.total;
              }
              loading.close();
            });
        } else {
          const params = {
            pageSize: this.pageSize,
            pageNum: this.pageNum,
            kindId: this.search.kind ? this.search.kind : undefined,
            zStage: this.search.stageName ? this.search.stageName : undefined,
            majorId: this.search.zyId ? this.search.zyId : undefined,
            typeId: this.search.type ? this.search.type : undefined,
            userId: this.userInfo ? this.userInfo.id : undefined,
            isScreen: 1,
            courseId: this.search.kcId,
            name: this.search.name,
          };
          /* 试卷 */
          answerClient.getNewQuestionPaperList(params).then((res) => {
            if (res.code) {
              this.list = [];
              this.total = 0;
            } else {
              this.list = res.rows;
              this.total = res.total;
            }
            loading.close();
          });
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    /* --------------------搜索--------------------------------- */
    // 筛选
    isOk(val) {
      this.search = val;

      // this.getList()
      this.searchClick();
    },
    /* 搜索 */
    searchClick() {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    /* --------------------购买--------------------------------- */

    // 立即购买
    goOrder(type, item) {
      const token = getToken();
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem("userId"),
            item.name,
            type,
            item.name,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    /* 试卷bao做题 */
    goPage(item) {
      if (!this.userInfo) {
        this.$notify({
          title: "错误",
          message: "未登录，无法做题, 请先去登录",
          type: "error",
          duration: 2000,
        });
        return;
      }
      this.$router.push(`/all/examPack?packId=${item.id}`);
    },
    /* 试卷做题 */
    /* 做题 */
    goLearn(item, ifHave) {
      Vue.prototype.goExam(item.name, item.id, 4, 12, ifHave, item.seeNum);
    },
    /* 进入考点*/
    goPlace(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView;
        return;
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
